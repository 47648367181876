/* import './Styles.css'; */

/* Navigation Styles */
.nav {
    color: #050505;
    padding: .5rem;
}

.nav:hover {
    /* transform: scale(1.1);
    transition: transform .1s; */
    text-decoration: underline;
    text-decoration-style: dashed;
    color: #0F4BF1;
}

.chakra-heading.css-6lkkh6{
    text-align: center;
}

.icons {
    color: #333333;
    padding: 1rem;
    /* transition: transform 0.3s ease-in-out; */
}

.icons:hover {
    /* transform: scale(1.1); */
    color: #0F4BF1;
}

.landing-icon {
    border: 1px solid #464FEB;
    border-radius: 2rem;
}

/* Border Styles */
.css-1y3k3mc {
    border-bottom: 1px solid #E0E0E0;
}

.border {
    border-bottom: 2px solid #E0E0E0;
}

/* Card Styles */
.cards {
    transition-duration: 0.4s;
}

.cards:hover {
    color: #0F4BF1;
    transform: translateY(-2px);
    transition: transform .2s;
}

.cards:active {
    color: #03DAC6;
}

@media (max-width: 850px) {
    .cards:hover {
        transform: translateY(0px);
        transition: transform 0s;
    }
}

/* About Section */
.aboutgrid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-items: center;
}

@media (max-width: 850px) {
    .aboutgrid {
        display: flex;
        flex-wrap: wrap-reverse;
    }
}

.abouttext p {
    padding-bottom: 1rem;
    line-height: 1.5;
    font-size: large;
}

.abouttext {
    color: #333333;
}

@media (max-width: 850px) {
    .abouttext p {
        font-size: medium;
    }

    .abouttext {
        margin-top: 1rem;
    }
}

/* Image Styles */
.image {
    border: 1px solid black;
}

/* Link Styles */
.link {
    color: #0F4BF1;
}

.link:hover {
    text-decoration: underline;
}

/* Languages List */
.languages {
    list-style-type: square;
    margin-left: 1rem;
}

.languages li {
    float: left;
    margin-top: 1rem;
    margin-right: 3rem;
    font-size: large;
    font-family: monospace;
}

@media (max-width: 850px) {
    .languages li {
        font-size: medium;
    }
}

/* Box Styles */
.box {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2rem;
}

@media (max-width: 850px) {
    .box {
        display: flex;
        flex-wrap: wrap;
    }
}

/* About Me Section */
.aboutme-section {
    padding: 4rem;
    color: #E0E0E0;
}

/* Project Section Padding */
.projects-section {
    padding: 4rem;
}

/* Navigation Media Queries */
@media (max-width: 850px) {
    .chakra-stack.navhome.css-1ocidfa {
        margin-top: .5rem;
        margin-bottom: .5rem;
        font-size: 1rem;
    }

    .chakra-stack.navbox.css-1y0iuhv {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: 0;
        font-size: small;
    }
}

/* Landing Section */
@media (max-width: 850px) {
    .chakra-stack.css-5zjxuu {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width: 850px) {
    .chakra-heading.css-18j379d {
        font-size: medium;
    }

    .chakra-text.css-653kx9 {
        font-size: medium;
    }
}

/* Landing Section Title */
.chakra-heading.css-1apeglv, .chakra-heading.css-tiszkr {
    font-family: Helvetica;
}

@media (max-width: 850px) {
    .chakra-heading.css-1apeglv {
        font-size: xx-large;
    }

    .chakra-heading.css-tiszkr {
        font-size: x-large;
    }
}

/* Footer Section */
footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: #E0E0E0;
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: #050505;
    border-top: 1px solid #1E1E1E;
}

footer ul {
    list-style-type: none;
    color: #FAFAFA;
}

footer h1 {
    color: #464FEB;
    font-size: x-large;
    margin-bottom: 1rem;
    font-weight: bold;
}

footer li {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

footer a {
padding-top: .5rem;
padding-bottom: .5rem;
}

footer a:hover {
    text-decoration: underline;
    text-decoration-style: wavy;
}

footer p {
    margin-top: 2rem;
    color: grey;
}

.footer_logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_logo a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}

.footer_logo img {
    border-radius: 1rem;
}


.nav-logo {
    display: flex;
    border-radius: .5rem;
    width: 6%;
}

.nav-logo img {
    border-radius: .5rem;
}

@media (max-width: 850px) {
    .nav-logo {
        width: 0%;
    }
}

@media (max-width: 850px) {
    footer {
        display: flex;
        flex-direction: column;
    }

    .footer_logo {
        margin-bottom: 2rem;
    }
}
